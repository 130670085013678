import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { MonthEndClosureRepoImpl } from "domain/repository/MonthEndClosure/MonthEndClosureRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TariffTypeRepoImpl } from "domain/repository/TariffCode/TariffTypeRepoImpl";
import { useMonthEndClosureTracked } from "presentation/store/MonthEndClosure/MonthEndClosureProvider";
import { MonthEndClosureVM } from "presentation/viewModel/MonthEndClosure/MonthEndClosureVM";
import { useMemo } from "react";

export const useMonthEndClosureVM = () => {
    const [, setMonthEndClosureState] = useMonthEndClosureTracked();
    const monthEndClosureVM = useMemo(() =>
        MonthEndClosureVM({
            dispatch: [setMonthEndClosureState],
            monthEndClosureRepo: MonthEndClosureRepoImpl(),
            tariffTypeRepo: TariffTypeRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            companyRepo: CompanyRepoImpl(),
        }), [setMonthEndClosureState])

    return monthEndClosureVM
}

