import { MonthEndClosureConstant } from "presentation/constant/MonthEndClosure/MonthEndClosureConstant";
import { useMonthEndClosureVM } from "presentation/hook/MonthEndClosure/useMonthEndClosureVM";
import { useMonthEndClosureTracked } from "presentation/store/MonthEndClosure/MonthEndClosureProvider";
import { memo } from "react";
import { DialogModal, IconButton } from "veronica-ui-component/dist/component/core";
import MonthEndActivityLogContent from "./MonthEndActivityLogContent";

const MonthEndActivityLogModal: React.FC = () => {
    const monthEndClosureVM = useMonthEndClosureVM();
    const [monthEndClosureState] = useMonthEndClosureTracked();
    const { isShowActivityLogPanel } = monthEndClosureState;

    const handleCloseDialog = () => {
        monthEndClosureVM.closeActivityLogPanel();
    }



    return <><DialogModal style={{ minWidth: '62vw', minHeight: '82vh' }}
        appendTo='self'
        showButton={false}
        visible={isShowActivityLogPanel}
        positions="center"
        resizable={false}
        header={
            <div className="im-charge-data-search-confirm-modal-header">
                <span className="name">{MonthEndClosureConstant.Title.BUTTON_ACTIVITY_LOG}</span>
                <IconButton fileName='Icon-cross' size="medium" onClick={handleCloseDialog} tooltipDisable={true} />
            </div>
        }
        dialogContent={<MonthEndActivityLogContent />}
        className="im-charge-data-search-confirm-modal"
    /></>
};

export default memo(MonthEndActivityLogModal);