import { ActivityLogEntity } from "domain/entity/ActivityLog/ActivityLogEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { MonthEndClosureEntity } from "domain/entity/MonthEndClosure/MonthEndClosureEntity";
import { ReservationItemEntity } from "domain/entity/MonthEndClosure/ReservationItemEntity";
import { axiosGetData, axiosPostData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import reportAxiosInstance from "../axios/reportAxiosInstance";
import { MonthEndClosureRepository } from "./MonthEndClosureRepo";

export const MonthEndClosureRepoImpl = (): MonthEndClosureRepository => {

    const getMonthEndClosureEntities = async (): Promise<MonthEndClosureEntity[]> => {
        const url = "/v1/monthEndClosure";
        return await axiosGetData(reportAxiosInstance, url, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });
    }

    const getMonthEndReservationItemList = async (id: number): Promise<ReservationItemEntity[]> => {
        const url = "/v1/monthEndReservation";
        return await axiosGetData(reportAxiosInstance, `${url}/${id}`, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });
    }

    const saveMonthEnd = async (entity: MonthEndClosureEntity): Promise<ResponseEntity> => {
        const url = "/v1/saveMonthEnd";
        return await axiosPostData(reportAxiosInstance, `${url}`, entity);
    }

    const saveMonthReservation = async (entity: ReservationItemEntity): Promise<ResponseEntity> => {
        const url = "/v1/saveMonthReservation";
        return await axiosPostData(reportAxiosInstance, `${url}`, entity);
    }

    const searchActivityLogForLastOstChgProcess = async (): Promise<ActivityLogEntity[]> => {
        const url = "/v1/getActivityLogForLastOstChgProcess";
        return await axiosGetData(referenceAxiosInstance, url, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });
    }

    const getMonthlyRevenueReport = async (rows: MonthEndClosureEntity[]): Promise<ResponseEntity> => {
        const url = "/v1/getMonthlyRevenueReport";
        return await axiosPostData(reportAxiosInstance, `${url}`, rows);
    }

    const getTurnoverCheckReport = async (rows: MonthEndClosureEntity[]): Promise<ResponseEntity> => {
        const url = "/v1/getTurnoverCheckReport";
        return await axiosPostData(reportAxiosInstance, `${url}`, rows);
    }

    const onFinalizeMonth = async (row: MonthEndClosureEntity): Promise<ResponseEntity> => {
        const url = "/v1/finalizeMonth";
        return await axiosPostData(reportAxiosInstance, `${url}`, row);
    }

    const onStartMonth = async (row: MonthEndClosureEntity): Promise<ResponseEntity> => {
        const url = "/v1/startMonth";
        return await axiosPostData(reportAxiosInstance, `${url}`, row);
    }

    const onCloseMonth = async (row: MonthEndClosureEntity): Promise<ResponseEntity> => {
        const url = "/v1/closeMonth";
        return await axiosPostData(reportAxiosInstance, `${url}`, row);
    }

    return {
        getMonthEndClosureEntities: getMonthEndClosureEntities,
        getMonthEndReservationItemList: getMonthEndReservationItemList,
        saveMonthEnd: saveMonthEnd,
        saveMonthReservation: saveMonthReservation,
        searchActivityLogForLastOstChgProcess: searchActivityLogForLastOstChgProcess,
        getMonthlyRevenueReport: getMonthlyRevenueReport,
        getTurnoverCheckReport: getTurnoverCheckReport,
        onFinalizeMonth: onFinalizeMonth,
        onStartMonth: onStartMonth,
        onCloseMonth: onCloseMonth,
    }
}