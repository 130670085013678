export interface MonthEndClosureEntity {
    id: number,
    year?: number | null,
    month?: number | null,
    fromDate?: Date | null,
    toDate?: Date | null,
    status: string,
    turnover?: number | null,
    estimation?: number | null,
    activeInd?: string | null,
    updatedDateTime?: Date | null,

    [key: string]: string | Date | boolean | number | null | undefined | Object
}

export const EMPTY_MONTH_END_CLOSURE_ENTITY: MonthEndClosureEntity = {
    id: 0,
    year: null,
    month: null,
    fromDate: null,
    toDate: null,
    status: "",
    turnover: null,
    estimation: null,
    activeInd: null,
    updatedDateTime: null
}