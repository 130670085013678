import { MonthEndStatusPolicy } from "constants/monthEnd/MonthEndStatusPolicy";
import { MonthEndClosureConstant } from "presentation/constant/MonthEndClosure/MonthEndClosureConstant";
import { useMonthEndClosureVM } from "presentation/hook/MonthEndClosure/useMonthEndClosureVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useMonthEndClosureTracked } from "presentation/store/MonthEndClosure/MonthEndClosureProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const MonthEndClosureTitleBar: React.FC = () => {
    const MONTH_END_CLOSURE_CONSTANT = MonthEndClosureConstant.Title;
    const monthEndClosureVM = useMonthEndClosureVM();
    const [monthEndClosureState] = useMonthEndClosureTracked();
    const { selectedRows, isMonthEndHdrUpdate } = monthEndClosureState;
    const [isLoading, setIsLoading] = useState(false);
    const messageBarVM = useMessageBarVM();

    const handleStartMonth = useCallback(() => {
        if (selectedRows.length === 1) {
            monthEndClosureVM.onStartMonthClick(selectedRows[0]).then(data => {
                if (data.success) {
                    messageBarVM.showSuccess("Start Month Successfully.");
                    monthEndClosureVM.initMonthEndClosureList().then(data => {
                        setIsLoading(false);
                    })
                } else {
                    messageBarVM.showError(data.data ?? "Error When Start Month.");
                    setIsLoading(false);
                }
            });
        } else {
            messageBarVM.showError("Please select a record.");
        }
    }, [messageBarVM, monthEndClosureVM, selectedRows]);

    const handleCloseMonth = useCallback(() => {
        if (selectedRows.length === 1) {
            monthEndClosureVM.onCloseMonthClick(selectedRows[0]).then(data => {
                if (data.success) {
                    messageBarVM.showSuccess("Close Month Successfully.");
                    monthEndClosureVM.initMonthEndClosureList().then(data => {
                        setIsLoading(false);
                    })
                } else {
                    messageBarVM.showError(data.data ?? "Error When Close Month.");
                    setIsLoading(false);
                }
            });
        } else {
            messageBarVM.showError("Please select a record.");
        }
    }, [messageBarVM, monthEndClosureVM, selectedRows]);

    const handleMonthlyRevenue = useCallback(() => {
        setIsLoading(true);
        monthEndClosureVM.onMonthlyRevenueClick(selectedRows).then(data => {
            if (!data) {
                messageBarVM.showError("Failed to view Monthly Revenue Report");
            }
            setIsLoading(false);
        })
    }, [messageBarVM, monthEndClosureVM, selectedRows]);

    const handleTurnoverCheck = useCallback(() => {
        setIsLoading(true);
        monthEndClosureVM.onTurnoverCheckClick(selectedRows).then(data => {
            if (!data) {
                messageBarVM.showError("Failed to view Turnover Check Report");
            }
            setIsLoading(false);
        })
    }, [messageBarVM, monthEndClosureVM, selectedRows]);

    const handleFinalizeMonth = useCallback(() => {
        if (selectedRows.length === 1) {
            if (selectedRows[0].activeInd === "N") {
                messageBarVM.showError("Active Ind is 'N',Can not do Finalize Month");
                return;
            }
            setIsLoading(true);
            monthEndClosureVM.onFinalizeMonthClick(selectedRows[0]).then(data => {
                if (data.success) {
                    messageBarVM.showSuccess("Finalize Successfully.");
                    monthEndClosureVM.initMonthEndClosureList().then(data => {
                        setIsLoading(false);
                    })
                } else {
                    messageBarVM.showError(data.data ?? "Error When finalize month end.");
                    setIsLoading(false);
                }
            })
        } else {
            messageBarVM.showError("Please select a record.");
        }
    }, [messageBarVM, monthEndClosureVM, selectedRows]);

    const handleReservation = useCallback(() => {
        if (selectedRows.length === 1) {
            setIsLoading(true);
            monthEndClosureVM.onReservationClick(selectedRows[0]).then(data => {
                setIsLoading(false);
            })
        } else {
            messageBarVM.showError("Please select a record.");
        }
    }, [messageBarVM, monthEndClosureVM, selectedRows]);

    const handleActivityLog = useCallback(() => {
        if (selectedRows.length === 1) {
            setIsLoading(true);
            monthEndClosureVM.onActivityLogClick().then(data => {
                setIsLoading(false);
            })
        } else {
            messageBarVM.showError("Please select a record.");
        }
    }, [messageBarVM, monthEndClosureVM, selectedRows.length]);

    const isCloseMonthDisable = () => {
        if (!isMonthEndHdrUpdate && selectedRows.length > 0 && selectedRows.every(entity => entity.status === MonthEndStatusPolicy.STATUS_WIP
            || entity.status === MonthEndStatusPolicy.STATUS_CLOSED
            || entity.status === MonthEndStatusPolicy.STATUS_GENERATED)) return false;
        return true;
    }

    const isStartMonthDisable = () => {
        if (!isMonthEndHdrUpdate && selectedRows.length > 0 && selectedRows.every(entity => entity.status === MonthEndStatusPolicy.STATUS_ERROR
            || entity.status === MonthEndStatusPolicy.STATUS_WIP
            || entity.status === MonthEndStatusPolicy.STATUS_NEW
            || entity.status === MonthEndStatusPolicy.STATUS_GENERATED)) return false;
        return true;
    }

    const isFinalizeDisable = () => {
        if (!isMonthEndHdrUpdate && selectedRows.length > 0 && selectedRows.every(entity => entity.status === MonthEndStatusPolicy.STATUS_WIP || entity.status === MonthEndStatusPolicy.STATUS_CLOSED)) return false;
        return true;
    }

    const isActivityLogDisable = () => {
        if (!isMonthEndHdrUpdate && selectedRows.length > 0 && selectedRows.every(entity => entity.status !== MonthEndStatusPolicy.STATUS_FINALIZED)) return false;
        return true;
    }

    return <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
        <HeaderTitle>{MONTH_END_CLOSURE_CONSTANT.TITLE}</HeaderTitle>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        <StyledAction className="tm-animated-wrapper">
            <HPHButton label={MONTH_END_CLOSURE_CONSTANT.BUTTON_START_MONTH} size={"Small"} theme={"Secondary"} disabled={isStartMonthDisable()} onClick={handleStartMonth} />
            <HPHButton label={MONTH_END_CLOSURE_CONSTANT.BUTTON_CLOSE_MONTH} size={"Small"} theme={"Secondary"} disabled={isCloseMonthDisable()} onClick={handleCloseMonth} />
            <div className="add-seperator" />
            <HPHButton label={MONTH_END_CLOSURE_CONSTANT.BUTTON_RESERVATION} size={"Small"} theme={"Secondary"} disabled={isActivityLogDisable()} onClick={handleReservation} />
            <div className="add-seperator" />
            <HPHButton label={MONTH_END_CLOSURE_CONSTANT.BUTTON_MONTHLY_REVENUE} size={"Small"} theme={"Secondary"} disabled={isActivityLogDisable()} onClick={handleMonthlyRevenue} />
            <HPHButton label={MONTH_END_CLOSURE_CONSTANT.BUTTON_TURNOVER_CHECK} size={"Small"} theme={"Secondary"} disabled={isActivityLogDisable()} onClick={handleTurnoverCheck} />
            <div className="add-seperator" />
            <HPHButton label={MONTH_END_CLOSURE_CONSTANT.BUTTON_FINALIZE_MONTH} size={"Small"} theme={"Secondary"} disabled={isFinalizeDisable()} onClick={handleFinalizeMonth} />
            <div className="add-seperator" />
            <HPHButton label={MONTH_END_CLOSURE_CONSTANT.BUTTON_ACTIVITY_LOG} size={"Small"} theme={"Primary"} disabled={isActivityLogDisable()} onClick={handleActivityLog} />
        </StyledAction>
    </Sidebarheader>
}

export default memo(MonthEndClosureTitleBar);