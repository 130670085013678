export const MonthEndClosureConstant = {
    Title: {
        CATEGORY: "Month-End",
        TITLE: "Month End Closure",
        BUTTON_START_MONTH: "Start Month",
        BUTTON_CLOSE_MONTH: "Close Month",
        BUTTON_RESERVATION: "Reservation",
        BUTTON_MONTHLY_REVENUE: "Monthly Revenue Report",
        BUTTON_TURNOVER_CHECK: "Turnover Check Report",
        BUTTON_FINALIZE_MONTH: "Finalize Month",
        BUTTON_ACTIVITY_LOG: "Activity Log",
    },
    Table: {
        YEAR: "Year",
        MONTH: "Month",
        FROM_DATE: "From Date",
        TO_DATE: "To Date",
        STATUS: "Status",
        TURNOVER: "Turnover",
        ESTIMATION: "Estimation",
        UPDATE_DATETIME: "Updated Date Time",
        ACTIVE_IND: "Active Ind.",

    },
    Reservation: {
        TITLE: "Reservation Item List",
        BILL_TO_COMPANY: "Bill-to Company",
        TARIFF_TYPE: "Tariff Type",
        TARIFF_CODE: "Tariff Code",
        NUMBER_OF_CONTAINER: "Number of Container",
        TURNOVER_AMOUNT: "Turnover Amount",
        ACTIVE_IND: "Active Ind.",

    },
    ActivityLog: {
        LOG_DATE_TIME: "Log Date Time",
        LOGGED_BY: "Logged By",
        PROCESS_ID: "Process Id",
        OPERATING_COMPANY: "Operating Company",
        OPERATING_UNIT: "Operating Unit",
        MESSAGE: "Message",
    },
}
