export interface ReservationItemEntity {
    id: number | null,
    monthEndHdrId: number,
    tariffType?: string | null,
    tariffCode?: string | null,
    numberOfChargeQty?: number | null,
    turnOverAmount?: number | null,
    billToCompany?: string | null,
    activeInd?: string | null,
    tempKey?: string | null,

    [key: string]: string | Date | boolean | number | null | undefined | Object
}

export const EMPTY_RESERVATION_ITEM_ENTITY: ReservationItemEntity = {
    id: null,
    monthEndHdrId: 0,
    tariffType: "",
    tariffCode: "",
    numberOfChargeQty: null,
    turnOverAmount: null,
    billToCompany: "",
    activeInd: "Y",
    tempKey: null,
}