import { SelectionChangedEvent } from "ag-grid-community";
import { ReservationItemEntity } from "domain/entity/MonthEndClosure/ReservationItemEntity";
import { MonthEndClosureConstant } from "presentation/constant/MonthEndClosure/MonthEndClosureConstant";
import { INITIAL_RESERVATION_ITEM_COL_DEF, transferRowData } from "presentation/constant/MonthEndClosure/ReservationItemColumnDefinition";
import { useMonthEndClosureVM } from "presentation/hook/MonthEndClosure/useMonthEndClosureVM";
import { MonthEndClosureModel } from "presentation/model/MonthEndClosure/MonthEndClosureModel";
import { convertDateToDateForComponent, convertDateToDateTimeForComponent } from "presentation/utils/timeUtil";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo } from "react";
import { HPHBreadcrumb, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const ReservationItemTablePanel = ({ monthEndClosureState }: { monthEndClosureState: MonthEndClosureModel }) => {
    const monthEndClosureVM = useMonthEndClosureVM();
    const { currentSelectedRow, reservationItemList, isAddReservation, isEditReservation } = monthEndClosureState;

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        monthEndClosureVM.updateReservationSelectedRows(selectedRows);
    }, [monthEndClosureVM])

    const handleRowDoubleClick = useCallback((entity: ReservationItemEntity) => {
        monthEndClosureVM.onReservationRowDoubleClick(entity);
    }, [monthEndClosureVM])

    const handleAddClick = useCallback(() => {
        monthEndClosureVM.onReservationAddClick(currentSelectedRow.id);
    }, [currentSelectedRow.id, monthEndClosureVM])


    const handleDtlClose = () => {
        monthEndClosureVM.onCloseDtlClick();
    }

    const memoReservationItemTable = useMemo(() => {

        return (
            <>
                <NbisTable
                    id='month-end-reservation-table'
                    isNewColumnSetting={true}
                    headerLabel={MonthEndClosureConstant.Reservation.TITLE}
                    columns={INITIAL_RESERVATION_ITEM_COL_DEF}
                    data={transferRowData(reservationItemList ?? [])}
                    showPaginator={false}
                    editable={false}
                    showAddIcon={!(isAddReservation || isEditReservation)}
                    onAddClick={handleAddClick}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    selectionMode={false}
                    isRowHighligted={true}
                    rowSelection={"single"}
                    onSelectionChanged={handleSelectionChange}
                    onRowDoubleClick={(e: any, entity: ReservationItemEntity) => handleRowDoubleClick(entity)}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 175px)"
                />
            </>
        );
    }, [handleAddClick, handleRowDoubleClick, handleSelectionChange, isAddReservation, isEditReservation, reservationItemList])

    return <>
        <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
            <HeaderWithBackButton callback={handleDtlClose}>
                <Breadcrumb>
                    <HPHBreadcrumb breadcrumbData={[{ title: MonthEndClosureConstant.Title.CATEGORY },
                    { title: MonthEndClosureConstant.Title.TITLE }]} onCurrentClick={() => { }}>
                    </HPHBreadcrumb>
                </Breadcrumb>
            </HeaderWithBackButton>
        </Sidebarheader>
        <>
            <div className="headerInfo" style={{ height: '5vh' }}>
                <div className="nineColumns">
                    <div className={"item"}>
                        <div className={"label"}>
                            {MonthEndClosureConstant.Table.YEAR}
                        </div>
                        <div className={"value"}>
                            {currentSelectedRow?.year}
                        </div>
                    </div>
                    <div className={"item"}>
                        <div className={"label"}>
                            {MonthEndClosureConstant.Table.MONTH}
                        </div>
                        <div className={"value"}>
                            {currentSelectedRow?.month}
                        </div>
                    </div>

                    <div className={"item"}>
                        <div className={"label"}>
                            {MonthEndClosureConstant.Table.FROM_DATE}
                        </div>
                        <div className={"value"}>
                            {convertDateToDateForComponent(currentSelectedRow?.fromDate)}
                        </div>
                    </div>

                    <div className={"item"}>
                        <div className={"label"}>
                            {MonthEndClosureConstant.Table.TO_DATE}
                        </div>
                        <div className={"value"}>
                            {convertDateToDateForComponent(currentSelectedRow?.toDate)}
                        </div>
                    </div>

                    <div className={"item"}>
                        <div className={"label"}>
                            {MonthEndClosureConstant.Table.STATUS}
                        </div>
                        <div className={"value"}>
                            {currentSelectedRow?.status}
                        </div>
                    </div>
                    <div className={"item"}>
                        <div className={"label"}>
                            {MonthEndClosureConstant.Table.TURNOVER}
                        </div>
                        <div className={"value"}>
                            {currentSelectedRow?.turnover as number}
                        </div>
                    </div>
                    <div className={"item"}>
                        <div className={"label"}>
                            {MonthEndClosureConstant.Table.ESTIMATION}
                        </div>
                        <div className={"value"}>
                            {currentSelectedRow?.estimation as number}
                        </div>
                    </div>
                    <div className={"item"}>
                        <div className={"label"}>
                            {MonthEndClosureConstant.Table.ACTIVE_IND}
                        </div>
                        <div className={"value"}>
                            {currentSelectedRow?.activeInd}
                        </div>
                    </div>
                    <div className={"item"}>
                        <div className={"label"}>
                            {MonthEndClosureConstant.Table.UPDATE_DATETIME}
                        </div>
                        <div className={"value"}>
                            {convertDateToDateTimeForComponent(currentSelectedRow?.updatedDateTime)}
                        </div>
                    </div>
                </div>
            </div>
        </>
        <TableWrapper>
            {monthEndClosureState.isLoading && <Loader Indicator="Spinner" size="Medium" />}
            {memoReservationItemTable}
        </TableWrapper>
    </>;
}

export default memo(ReservationItemTablePanel);