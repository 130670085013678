import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { MonthEndClosureConstant } from "./MonthEndClosureConstant";

const SCREEN_CONSTANT = MonthEndClosureConstant.ActivityLog;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let numberFieldList: string[] = [];
export const INITIAL_MONTH_END_ACTIVITY_LOG_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.LOG_DATE_TIME,
        field: "createdDateTime",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
        dataType: "dateTime"
    },
    {
        headerName: SCREEN_CONSTANT.LOGGED_BY,
        field: "createdBy",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 100,
    },
    {
        headerName: SCREEN_CONSTANT.PROCESS_ID,
        field: "processId",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 380,
    },
    {
        headerName: SCREEN_CONSTANT.OPERATING_COMPANY,
        field: "operatingCompany",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.OPERATING_UNIT,
        field: "operatingUnit",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 160,
    },
    {
        headerName: SCREEN_CONSTANT.MESSAGE,
        field: "message",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 500,
    },
].map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, [], [], [], cellRenderers, numberFieldList);
});
export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}