import { EMPTY_MONTH_END_CLOSURE_MODEL } from "presentation/model/MonthEndClosure/MonthEndClosureModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: MonthEndClosureProvider,
    useTracked: useMonthEndClosureTracked
} = createContainer(() => useState(EMPTY_MONTH_END_CLOSURE_MODEL), { concurrentMode: true });
export { MonthEndClosureProvider, useMonthEndClosureTracked };

