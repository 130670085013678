import { SelectionChangedEvent } from "ag-grid-community";
import { MonthEndClosureEntity } from "domain/entity/MonthEndClosure/MonthEndClosureEntity";
import { INITIAL_MONTH_END_CLOSURE_COL_DEF, transferRowData } from "presentation/constant/MonthEndClosure/MonthEndClosureColumnDefinition";
import { useMonthEndClosureVM } from "presentation/hook/MonthEndClosure/useMonthEndClosureVM";
import { useMonthEndClosureTracked } from "presentation/store/MonthEndClosure/MonthEndClosureProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";

const MonthEndClosureTablePanel = () => {
    const monthEndClosureVM = useMonthEndClosureVM();
    const [monthEndClosureState] = useMonthEndClosureTracked();
    const { monthEndClosureList } = monthEndClosureState;

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        monthEndClosureVM.updateSelectedRows(selectedRows);
    }, [monthEndClosureVM])

    const handleRowDoubleClick = useCallback((entity: MonthEndClosureEntity) => {
        monthEndClosureVM.onRowDoubleClick(entity);
    }, [monthEndClosureVM])


    const memoMonthEndClosureTable = useMemo(() => {

        return (
            <>
                <NbisTable
                    id='month-end-closure-table'
                    isNewColumnSetting={true}
                    columns={INITIAL_MONTH_END_CLOSURE_COL_DEF}
                    data={transferRowData(monthEndClosureList ?? [])}
                    showPaginator={false}
                    editable={false}
                    showAddIcon={false}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    selectionMode={false}
                    isRowHighligted={true}
                    rowSelection={"multiple"}
                    onSelectionChanged={handleSelectionChange}
                    onRowDoubleClick={(e: any, entity: MonthEndClosureEntity) => handleRowDoubleClick(entity)}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 125px)"
                />
            </>
        );
    }, [handleRowDoubleClick, handleSelectionChange, monthEndClosureList])


    return <><TableWrapper>
        {monthEndClosureState.isLoading && <Loader Indicator="Spinner" size="Medium" />}
        {memoMonthEndClosureTable}
    </TableWrapper>
    </>;

}

export default memo(MonthEndClosureTablePanel);